import React from "react";
import PageTop from "../components/PageTop";
import HeroCard from "../components/HeroCard";
import SimpleCard from "../components/SimpleCard";
import { Helmet } from "react-helmet";
import "../styles/art_and_culture.css";
import ArtSlider from "../components/Art-slider";
import BeforeFooter from "../components/BeforeFooter";
import artculturetp from "../Img/Art-n-Culture.webp";
import art from "../extra/87160210_2517938021804805_5713992411487666176_n.webp";
function Art_and_culture(props) {
  return (
    <>
      {props.data.map((data, index) => {
        return (
          <>
            <Helmet>
              <title>
                Shiv Jayanti Celebrations in Nilanga: Cultural Festivities,
                Rangoli World Record, and Blood Donation Camp
              </title>
              <meta
                name="keywords"
                content=" shiv jayanti, chhatrapati shivaji maharaj, Rangoli world record, harit shiv janmotsav, shiv sankalp, shiva jayanti, cultural shiv janmotsav, vichardhara, bharat ratna, eternal shiva jayanti, nilanga, blood donation camp, chhatrapti shivaji maharaj chowk   "
              />
              <meta
                name="description"
                content="Celebrate Shiv Jayanti with us in Nilanga – join the cultural festivities, Rangoli World Record, and blood donation camp at Nilanga. Embrace the spirit of Shiv Sankalp and Vichardhara "
              />
              <link
                rel="canonical"
                href="https://akkafoundation.in/art&culture/"
              />
            </Helmet>
            <PageTop text={data.AC_pt_title} url={artculturetp} alt="Art and Culture" />
            <HeroCard
              align="row"
              url={art}
              desc={data.AC_hc_desc}
              span={data.AC_hc_span}
              title={data.AC_hc_title}
            />

            <div className="art-independent parent">
              <div className="art-independent-container container-small">
                <div className="art-left">
                  <h2>
                    {data.AC_is1_title} <span>{data.AC_is1_span}</span>{" "}
                  </h2>
                  <p>{data.AC_is1_desc}</p>
                </div>
                <div className="art-right">
                  <div className="art-img bg-img"></div>
                </div>
              </div>
            </div>

            <div className="art-year-slider parent">
              <div className="art-year-slider-container container-small">
                <div className="art-slider-box">
                  <ArtSlider data={props.data} />
                </div>
              </div>
            </div>

            <div className="art-is3 parent">
              <div className="art-is3-container container-small">
                <SimpleCard
                  title={data.AC_slider_left_before_fi_title}
                  desc={data.AC_slider_left_before_fi_desc}
                />
                <SimpleCard
                  title={data.AC_slider_right_before_fi_title}
                  desc={data.AC_slider_right_before_fi_desc}
                />
              </div>
            </div>

            {/* <div className="art-slider parent">
              <div className="art-slider-container container-small">
                <h2>
                  {data.EP_fi_title} <span>  {data.EP_fi_span} </span>
                </h2>
                <div className="slider-box">
                </div>
              </div>
            </div> */}

            <BeforeFooter bgcolor="var(--baiege)" data={props.data} />
          </>
        );
      })}
    </>
  );
}

export default Art_and_culture;
