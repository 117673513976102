import React from "react";
import "./artimage.scss";
const ArtImage = ({open,image, onClick}) => {
  return (
    <div className="art-image-box bg-img" style={{background : `url(${image})`}} onClick={() => { onClick(); open(); }}>
      
    </div>
  );
};

export default ArtImage;
