import React, { useEffect, useState } from "react";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ArtImage from "../../components/artscomponents/ArtImage";
import ArtistContact from "../../components/artscomponents/ArtistContact";
import HeroCard from "../../components/HeroCard";
import PageTop from "../../components/PageTop";
import secondimg from "../../Img/artist/Ravishankar.png";
import img24 from "../../Img/Ravishankar/img10.jpeg";
import img25 from "../../Img/Ravishankar/img11.jpeg";
import img26 from "../../Img/Ravishankar/img12.jpeg";
import img27 from "../../Img/Ravishankar/img13.jpeg";
import img28 from "../../Img/Ravishankar/img14.jpeg";
import img16 from "../../Img/Ravishankar/img2.png";
import img17 from "../../Img/Ravishankar/img3.png";
import img18 from "../../Img/Ravishankar/img4.png";
import img20 from "../../Img/Ravishankar/img5.png";
import img19 from "../../Img/Ravishankar/img6.png";
import img21 from "../../Img/Ravishankar/img7.png";
import img22 from "../../Img/Ravishankar/img8.png";
import img23 from "../../Img/Ravishankar/img9.jpeg";
import "./sustainableart.css";
import toast from "react-hot-toast";
import axios from "axios";
const Ravishankar_Barskar = (props) => {
  const [toggle, settoggle] = useState(false);
  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };
  // const images = [
  //   img1,img2,img3,img4,img5,img6,img7,img8,img9, img10,img11,img12,img13
  // ];

  const [selectedId, setSelectedId] = useState(1);
  const imagedata = [
    {
      id: 16,
      image: img16,
    },
    {
      id: 17,
      image: img17,
    },
    {
      id: 18,
      image: img18,
    },
    {
      id: 19,
      image: img19,
    },
    {
      id: 20,
      image: img20,
    },
    {
      id: 21,
      image: img21,
    },
    {
      id: 22,
      image: img22,
    },
    {
      id: 23,
      image: img23,
    },
    {
      id: 24,
      image: img24,
    },
    {
      id: 25,
      image: img25,
    },
    {
      id: 26,
      image: img26,
    },
    {
      id: 27,
      image: img27,
    },
    {
      id: 28,
      image: img28,
    },
  ];

  const [fetcheddata, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.akkafoundation.in/api/arts/?artist_id=3&language_id=${
          props.toggle ? 2 : 1
        }`
      );
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      toast.error("Server offline!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.toggle]);
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <PageTop text={data.artist_name3} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.ravishankar_intro}
              span={data.heading_artist1}
              title={data.heading_artist}
            />

            <div className="mangesh parent">
              <div className="mangesh-container container-small">
                {fetcheddata &&
                  fetcheddata.map((item, index) => (
                    <ArtImage
                      key={index}
                      open={open}
                      image={item.image}
                      onClick={() => setSelectedId(item.id)}
                    />
                  ))}
              </div>
            </div>

            {toggle && (
              <ArtDetail close={close} id={selectedId} data={props.data} />
            )}
          </>
        );
      })}

      <ArtistContact toggle={props.toggle} />
    </>
  );
};

export default Ravishankar_Barskar;
