import React, { useEffect, useState } from "react";
import "./sustainableart.css";
import HeroCard from "../../components/HeroCard";
import secondimg from "../../Img/artist/Mangesh.png";
import PageTop from "../../components/PageTop";
import Mangesh from "../../components/Mangesh";
import ArtImage from "../../components/artscomponents/ArtImage";
import ArtDetail from "../../components/artscomponents/ArtDetail";
import ComplexCard from "../../components/ComplexCard";
import img9 from "../../Img/Mangesh/img1.webp";
import img10 from "../../Img/Mangesh/img2.png";
import img11 from "../../Img/Mangesh/img4.webp";
import img12 from "../../Img/Mangesh/img5.webp";
import img13 from "../../Img/Mangesh/img3.png";
import img14 from "../../Img/Mangesh/img7.png";
import img15 from "../../Img/Mangesh/img8.png";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import ArtistContact from "../../components/artscomponents/ArtistContact";

const Mangesh_Nipanikar = (props) => {
  const [toggle, settoggle] = useState(false);

  const open = () => {
    settoggle(true);
  };
  const close = () => {
    settoggle(false);
  };
  const [selectedId, setSelectedId] = useState(1);


  const [fetcheddata, setdata] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://api.akkafoundation.in/api/arts/?artist_id=2&language_id=${props.toggle ? 2 : 1}`
      );
      console.log(response.data);
      setdata(response.data);
    } catch (error) {
      toast.error("Server offline!");
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.toggle]);
  return (
    <>
      {props.data.map((data) => {
        return (
          <>
            <PageTop text={data.artist_name1} url={secondimg} />
            <HeroCard
              align="row"
              url={secondimg}
              desc={data.mangesh_intro}
              span={data.heading_artist1}
              title={data.heading_artist}
            />

            <div className="mangesh parent">
              <div className="mangesh-container container-small">
                {fetcheddata && fetcheddata.map((item, index) => (
                  <ArtImage
                    key={index}
                    open={open}
                    image={item.image}
                    onClick={() => setSelectedId(item.id)}
                  />
                ))}
              </div>
            </div>

            {toggle && (
              <ArtDetail close={close} id={selectedId} data={props.data} />
            )}
          </>
        );
      })}
      <Toaster position="top-center" reverseOrder={false} />
      <ArtistContact toggle={props.toggle}/>
    </>
  );
};

export default Mangesh_Nipanikar;
