import React, { useEffect, useState } from "react";
import "./artistcontact.scss";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const ArtistContact = (props) => {
  const [artists, setArtists] = useState([]);
  const [formData, setFormData] = useState({
    user_name: "",
    user_contact: "",
    user_email: "",
    artist_name: "N/A",
    message: "",
  });
  const [loading,setloading] = useState(false)
  const fetchArtist = async () => {
    try {
      const response = await axios.get(
        "https://api.akkafoundation.in/api/artistnames/"
      );
      setArtists(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchArtist();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setloading(true);
    try {
      const response = await axios.post(
        "https://api.akkafoundation.in/api/artist-contact/",
        formData
      );
      toast.success("Form submitted successfully");
      setFormData({
        user_name: "",
        user_contact: "",
        user_email: "",
        artist_name: "N/A",
        message: "",
      });
      setloading(false)
    } catch (error) {
      toast.error("Nework error!")
    }
  };
  return (
    <>
      <div className="artist-contact-parent parent">
        <div className="artist-contact-container container-small">
          <div className="artist-contact-title">
            {
              !props.toggle ? <><span>Discover our artists &nbsp;</span>  Share your info</>: <><span>कलाकारांशी संपर्क &nbsp;</span> साधण्यासाठी खालील माहिती भरा</>
            }
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                placeholder="Name"
                name="user_name"
                value={formData.user_name}
                onChange={handleChange}
                required
              />
              <input
                type="number"
                placeholder="Contact"
                name="user_contact"
                value={formData.user_contact}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <input
                type="email"
                placeholder="Email"
                name="user_email"
                value={formData.user_email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-row">
              <select
                name="artist_name"
                value={formData.artist_name}
                onChange={handleChange}
                required
              >
                <option value="N/A" disabled>
                  Select Artist
                </option>
                {artists.map((data, index) => (
                  <option key={index} value={data.artist_name}>
                    {data.artist_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-row">
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            <div className="form-row">
              <button type="submit" value="Enquire Now" className="btn-active">
                {loading ? "Submitting..." : "Enquire Now"}
                
              </button>
            </div>
          </form>
        </div>
      </div>

      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
};

export default ArtistContact;
